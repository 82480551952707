
import { defineComponent, ref, onMounted, onBeforeMount } from "vue";
import { ApiBase } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";

interface Filter {
  name: string;
  manager: string;
  purpose: string;
}

export default defineComponent({
  name: "social-connections-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const data = ref<Filter>({
      name: "",
      manager: "",
      purpose: "",
    });
    const { t } = useI18n();
    const options = ref({
      managerOptions: [],
      managerLoading: false,
      purposeOptions: [],
    });

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["distribution_campaign_purpose"],
      });
      if (data.code == 0) {
        options.value.purposeOptions =
          data.data.distribution_campaign_purpose.items;
      }
    };

    const getUserSourceData = async (value?: string) => {
      options.value.managerLoading = true;
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {};

      const { data } = await ApiBase.getUserSourceData(params);
      options.value.managerLoading = false;
      if (data.code == 0) {
        options.value.managerOptions = data.data;
      }
    };

    const debounceUserSearch = _.debounce(getUserSourceData, 1000);

    const searchManagerItems = (query) => {
      debounceUserSearch(query);
    };

    const submit = () => {
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onBeforeMount(() => {
      getDropdownOptions();
    });
    return {
      data,
      formRef,
      t,
      submit,
      handleReset,
      searchManagerItems,
      options,
    };
  },
});
