
import { defineComponent, ref, onBeforeMount } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase, ApiWechatCommunity } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { commonChangeDefaultDate } from "@/core/directive/function/common";

export default defineComponent({
  name: "add-wechat-communities-modal",
  components: {},
  emits: ["update-list"],
  setup(props, { emit }) {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const addWechatCommunitiesModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      name: "",
      manager: "",
      purpose: "",
      dedicated_group_level: [],
      description: "",
      mb_segmentation: [],
      members: "",
      start_date: null,
      expiry_date: "",
      pro_active_actions: [],
      subsisting_actions: [],
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      start_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      purpose: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      manager: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      members: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      dedicated_group_level: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      pro_active_actions: [],
      subsisting_actions: [],
    });

    const managerOptions = ref([]);
    const managerLoading = ref<boolean>(false);
    const purposeOptions = ref([]);
    const dedicatedGroupLevelOptions = ref([]);
    const dedicatedGroupLevelLoading = ref<boolean>(false);

    const getInfluencerGroupSourceData = async () => {
      dedicatedGroupLevelLoading.value = true;
      const { data } = await ApiBase.getInfluencerGroupSourceData({
        max_item: "all",
      });
      dedicatedGroupLevelLoading.value = false;
      if (data.code == 0) {
        dedicatedGroupLevelOptions.value = data.data;
      }
    };

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "distribution_campaign_purpose",
          "wechat_community_pro-active_actions",
          "wechat_community_subsisting_actions",
        ],
      });
      if (data.code == 0) {
        purposeOptions.value = data.data.distribution_campaign_purpose.items;
        options.value.pro_active_actions =
          data.data["wechat_community_pro-active_actions"]["items"];
        options.value.subsisting_actions =
          data.data.wechat_community_subsisting_actions.items;
      }
    };

    const getUserSourceData = async (value?: string) => {
      managerLoading.value = true;
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {};

      const { data } = await ApiBase.getUserSourceData(params);
      managerLoading.value = false;
      if (data.code == 0) {
        managerOptions.value = data.data;
      }
    };

    const debounceUserSearch = _.debounce(getUserSourceData, 1000);

    const searchManagerItems = (query) => {
      debounceUserSearch(query);
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiWechatCommunity.addWechatCommunity(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addWechatCommunitiesModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(addWechatCommunitiesModalRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onBeforeMount(() => {
      getInfluencerGroupSourceData();
      getDropdownOptions();
    });

    return {
      commonChangeDefaultDate,
      loading,
      formData,
      rules,
      formRef,
      addWechatCommunitiesModalRef,
      submitButton,
      options,
      managerOptions,
      managerLoading,
      purposeOptions,
      dedicatedGroupLevelOptions,
      dedicatedGroupLevelLoading,
      t,
      searchManagerItems,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
